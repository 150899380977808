// Taken from material
import Vue from "vue";
import raf from "raf";
var isPrivateVue = function (ob) { return !!ob; };
export default Vue.extend({
    //name: 'MdPortal',
    //abstract: true,
    props: {
        mdAttachToParent: Boolean,
        mdTarget: Element,
    },
    data: function () {
        return {
            leaveTimeout: undefined,
            originalParentEl: null,
        };
    },
    computed: {
        transitionName: function () {
            if (!isPrivateVue(this)) {
                return "v";
            }
            var cOptions = this._vnode.componentOptions;
            if (!cOptions || !cOptions.children) {
                return "v";
            }
            var childrenComponent = cOptions.children[0];
            if (childrenComponent && childrenComponent.data) {
                var transition = childrenComponent.data.transition;
                if (transition && transition) {
                    return transition.name;
                }
                else if (childrenComponent.componentOptions &&
                    childrenComponent.componentOptions.propsData) {
                    if (childrenComponent.componentOptions.propsData) {
                        var transition_1 = childrenComponent.componentOptions.propsData.name;
                        if (transition_1) {
                            return transition_1;
                        }
                    }
                }
            }
            return "v";
        },
        leaveClass: function () {
            return this.transitionName + "-leave";
        },
        leaveActiveClass: function () {
            return this.transitionName + "-leave-active";
        },
        leaveToClass: function () {
            return this.transitionName + "-leave-to";
        },
    },
    watch: {
        mdTarget: function (newTarget, oldTarget) {
            this.changeParentEl(newTarget);
            if (oldTarget) {
                this.$forceUpdate();
            }
        },
    },
    mounted: function () {
        if (!this.originalParentEl) {
            this.originalParentEl = this.$el.parentNode;
            this.$emit("md-initial-parent", this.$el.parentNode);
        }
        if (this.mdAttachToParent &&
            this.$el.parentNode &&
            this.$el.parentNode.parentNode) {
            this.changeParentEl(this.$el.parentNode.parentNode);
        }
        else if (document) {
            this.changeParentEl(this.mdTarget || document.body);
        }
    },
    beforeDestroy: function () {
        if (this.$el.classList) {
            this.initDestroy();
        }
        else {
            this.killGhostElement(this.$el);
        }
    },
    methods: {
        getTransitionDuration: function (el) {
            var duration = window.getComputedStyle(el).transitionDuration;
            var num = parseFloat(duration);
            var unit = duration.match(/m?s/);
            if (unit) {
                unit = unit[0];
            }
            if (unit === "s") {
                return num * 1000;
            }
            if (unit === "ms") {
                return num;
            }
            return 0;
        },
        killGhostElement: function (el) {
            if (el.parentNode && this.originalParentEl) {
                this.changeParentEl(this.originalParentEl);
                if (this.$options) {
                    this.$options._parentElm = this.originalParentEl;
                }
                el.parentNode.removeChild(el);
            }
        },
        initDestroy: function (manualCall) {
            var _this = this;
            if (manualCall === void 0) { manualCall = false; }
            var el = this.$el;
            var isElement = function (node) { return !!node; };
            if (manualCall &&
                this.$el.nodeType === Node.COMMENT_NODE &&
                this.$vnode.elm &&
                isElement(this.$vnode.elm)) {
                el = this.$vnode.elm;
            }
            if (!el) {
                return;
            }
            el.classList.add(this.leaveClass);
            el.classList.add(this.leaveActiveClass);
            this.$nextTick().then(function () {
                if (!el) {
                    return;
                }
                el.classList.add(_this.leaveToClass);
                clearTimeout(_this.leaveTimeout);
                _this.leaveTimeout = window.setTimeout(function () {
                    if (!el) {
                        return;
                    }
                    _this.destroyElement(el);
                }, _this.getTransitionDuration(el));
            });
        },
        destroyElement: function (el) {
            var _this = this;
            raf(function () {
                el.classList.remove(_this.leaveClass);
                el.classList.remove(_this.leaveActiveClass);
                el.classList.remove(_this.leaveToClass);
                _this.$emit("md-destroy");
                _this.killGhostElement(el);
            });
        },
        changeParentEl: function (newTarget) {
            newTarget && newTarget.appendChild(this.$el);
        },
    },
    render: function () {
        var defaultSlot = this.$slots.default;
        if (!defaultSlot || !defaultSlot[0]) {
            throw new Error("No slot");
        }
        return defaultSlot[0];
    },
});
